import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { motion } from 'framer-motion';

import Layout from '../components/Layout';
import Seo from '../components/Seo';
import LogoLinks from '../components/LogoLinks';

import Bites from '../menus/Just a Nibble NYC Bites.pdf';
import DinnerMenupdf from '../menus/Just a Nibble NYC Dinner Menu.pdf';

const Seafoods = [
   {
      name: 'Montauk Lobster Roll',
      desc: 'with tarragon aioli',
   },
   {
      name: 'Shrimp Salad Roll',
      desc: 'with wasabi',
   },
   {
      name: 'Lemongrass Thai Shrimp Fritters',
      desc: 'Served with sweet chili sauce or a ginger soy drizzle',
   },
   {
      name: 'Shrimp & Asparagus Skewers',
      desc: 'with yuzu aioli and crispy rice',
   },
   {
      name: 'Crab Cakes',
      desc: 'with remoulade dip',
   },
   {
      name: 'Thai Mango Crab Salad',
      desc: 'with chili and Thai basil on a rice cracker',
   },
   {
      name: 'Citrus and Herb-Marinated Ahi Tuna ',
      desc: 'served atop a cucumber round',
   },
   {
      name: 'Asian Tuna Tartar',
      desc: 'With ginger, sesame, and yuzu aioli on wonton crisp',
   },
   {
      name: 'Wild Salmon Tartar ',
      desc: 'In a cucumber cup with mango red pepper relish and fennel fronds',
   },
   {
      name: 'Tandoori-Cured Salmon',
      desc: 'with mango chutney on papadum',
   },
   {
      name: 'Fennel-Cured Smoked Salmon',
      desc: 'with crème fraîche and salmon roe',
   },
   {
      name: 'Smoked Salmon & Goat Cheese Mousse',
      desc: 'with watercress on pumpernickel toast',
   },
   {
      name: 'Salmon Tartar Tostada',
      desc: 'with avocado, white bean, and pickled shallot',
   },
];

const Meats = [
   {
      name: 'Grilled Lollipop Lamb Chop +$2',
      desc: 'with horseradish gremolata',
   },
   {
      name: 'Curry Lamb/Duck/Goat',
      desc: 'in phyllo cup with tamarind glaze and cilantro',
   },
   {
      name: 'Braised Pork Belly',
      desc: 'on bao bun with Asian pickled vegetables',
   },
   {
      name: 'Bacon-Wrapped Date',
      desc: '',
   },
   {
      name: 'Pulled Pork',
      desc: 'on corn muffin with bread and butter pickles',
   },
   {
      name: 'Braised Short Rib',
      desc: 'with orange and sweet potato purée marmalade',
   },
   {
      name: 'Seared Dry-Aged Steak',
      desc: 'with blue cheese and pickled chiles on yucca chip',
   },
   {
      name: 'Beef Slider',
      desc: 'with swiss or muenster cheese, bacon-shallot jam, and arugula pesto',
   },
   {
      name: 'Ginger Beef Satay',
      desc: 'with tahini glaze',
   },
   {
      name: 'Guava BBQ Pulled Beef Slider',
      desc: 'with fennel chili slaw and pickled onions',
   },
   {
      name: 'Grandma Mini Meatball Marinara',
      desc: 'with burrata on grilled crostini',
   },
   {
      name: 'BBQ Brisket',
      desc: 'with picked onions on polenta cake',
   },
   {
      name: 'Buttermilk Fried Chicken',
      desc: 'with jalapeño jam on cheddar biscuit',
   },
   {
      name: 'Thai Chicken Satay Skewers',
      desc: 'with peanut sauce',
   },
   {
      name: 'Jerk Chicken Skewers',
      desc: 'with tamarind glaze',
   },
   {
      name: 'Chicken + Waffles',
      desc: 'with maple-bourbon glaze',
   },
];

const Tarts = [
   {
      name: 'Asparagus and Gruyère',
      desc: '',
   },
   {
      name: 'Wild Mushroom and Black Truffle Asiago',
      desc: '',
   },
   {
      name: 'Caramelized Onion, Crème Fraiche, and Thyme',
      desc: '',
   },
   {
      name: 'Truffle Mac and Cheese',
      desc: '',
   },
];

const Flatbreads = [
   {
      name: 'Goat Cheese, Medjool Dates, Thyme, and Honey',
      desc: '',
   },
   {
      name: 'Grilled Onion, Roasted Peppers, Fennel Sausage, and Pecorino',
      desc: '',
   },
   {
      name: 'Shaved Potato, Caramelized Onion, and Rosemary',
      desc: '',
   },
   {
      name: 'Mixed Winter Squash, Goat Cheese, and Walnuts',
      desc: '',
   },
   {
      name: 'Parmesan, Fresh Mozzarella, Tomato, and Basil',
      desc: '',
   },
];

const Crostinis = [
   {
      name: 'Lemon Ricotta Mousse and Balsamic Glaze',
      desc: '',
   },
   {
      name: 'Pomegranate Fig Jam and Lemon-Thyme Ricotta Mousse',
      desc: '',
   },
   {
      name: 'Eggplant, Vidalia Onion, Corn Caponata, and Mozzarella',
      desc: '',
   },
   {
      name: 'Beef Carpaccio, White Truffle, Arugula, and Parmesan',
      desc: '',
   },
   {
      name: 'Melted Brie, Honey, and Walnut',
      desc: '',
   },
   {
      name: 'Tart Apple, Blue Cheese, and Hazelnuts',
      desc: '',
   },
   {
      name: 'Feta, Oregano, and Fig Jam',
      desc: '',
   },
   {
      name: 'English Peas, Fava Beans, Fresh Ricotta, Mint, and Chervil',
      desc: '',
   },
   {
      name: 'Brie, Caramelized Onions, Poached Pear, and Fig Jam',
      desc: '',
   },
   {
      name: 'Mini Quesadilla Bites',
      desc: '',
   },
];

const Salads = [
   { name: 'Mixed baby greens', desc: 'toasted almonds, shaved fennel, yogurt mint dressing' },
   { name: 'Chopped salad', desc: 'sugar snap peas, breakfast radish, cucumber, buttermilk dressing' },
   { name: 'Arugula', desc: 'cucumber, radish, feta, mint, croutons' },
   { name: 'Iceberg wedge', desc: 'blue cheese, cherry tomatoes, bacon lardons' },
   { name: 'Mixed lettuce', desc: 'rainbow carrots, shaved beets, cucumber, black sesame seeds, lemon-ginger vinaigrette' },
   { name: 'Butter lettuce', desc: 'With chili and Thai basil on a rice cracker' },
   { name: 'Citrus and Herb-Marinated Ahi Tuna', desc: 'orange supremes, avocado, basil, orange vinaigrette' },
   { name: 'Mixed lettuce', desc: 'summer squash, cherry tomatoes, lemon vinaigrette' },
   { name: 'Frisée', desc: 'cherries, walnuts, dates, sherry vinaigrette' },
   { name: 'Red oak and lolla rosa lettuces', desc: 'roasted pears, chèvre, sweet roasted almonds, pear cider onion' },
   { name: 'Endive', desc: 'rainbow beets, caramelized walnuts, lemon-cream dressing' },
   { name: 'Heirloom tomatoes', desc: 'cucumbers, lemon, onion, mint, charred feta' },
   { name: 'Cumin-roasted baby carrots', desc: 'farro, kale, sherry vinaigrette' },
   { name: 'Cucumbers', desc: 'shaved beets, roasted shallots, apple cider vinaigrette' },
   { name: 'Quinoa', desc: 'grilled corn, black beans, celery, bell peppers, honey-lime vinaigrette' },
];

const Beefs = [
   { name: 'Beef Tenderloin +$3', desc: '' },
   { name: 'Roast Beef', desc: '' },
   { name: 'Flank Steak', desc: '' },
   { name: 'NY Strip Loin', desc: '' },
   { name: 'Ribeye Steak +$3', desc: '' },
   { name: 'Braised Short Ribs', desc: '' },
   { name: 'Hangar Steak', desc: '' },
];

const Chickens = [
   { name: 'Farmhouse Chicken', desc: '' },
   { name: 'Stuffed Chicken Breast', desc: 'with mushroom, fontina, basil and red pepper' },
   { name: 'Chicken Tagine', desc: '' },
   { name: 'Buttermilk Fried Chicken', desc: '' },
   { name: 'BBQ Chicken', desc: '' },
];

const Porks = [
   { name: 'Smoked Spiced Pork Belly', desc: 'with a sweet and sour glaze' },
   { name: 'St. Louis-Style Pork Ribs', desc: '' },
   { name: 'Smoked Pork Shoulder', desc: '' },
   { name: 'Pork Tenderloin Medallions', desc: '' },
];

const SeafoodsDinner = [
   { name: 'Seared Jumbo Scallops', desc: '' },
   { name: 'Pan-Fried Rainbow Trout', desc: '' },
   { name: 'Seared Wild Striped Bass', desc: '' },
   { name: 'Paella ', desc: 'with chorizo, chicken, mussels, clams, and shrimp' },
   { name: 'White Wine-Poached Salmon', desc: '' },
   { name: 'Sesame-Crusted Tuna Steak +$2', desc: '' },
   { name: 'Whole Roasted Market Fish', desc: 'stuffed with citrus and herbs' },
   { name: 'Red Snapper Fillet', desc: '' },
];

const Vegetarian = [
   { name: 'Cauliflower Steak', desc: 'with tahini purée, quinoa salad, and fennel purée' },
   { name: 'Chile-Braised Cranberry Beans', desc: 'with summer squash and corn in a tomato stew with crispy corn tostadas' },
   { name: 'Butternut Squash', desc: 'with swiss chard and fennel' },
   { name: 'Wild Mushroom Ragoût', desc: 'over soft polenta with parmesan cheese' },
   { name: 'Quinoa-Stuffed Red Pepper', desc: 'with pickled chilis and salsa verde' },
   { name: 'Pinto Bean Chili', desc: 'with mushrooms, winter squash, avocado, cotija cheese, crème fraîche, and corn bread' },
   { name: 'Potato and Kale Curry', desc: 'with slivered almonds and yogurt mint sauce over baked basmati rice' },
];

const Sides1 = [
   { name: 'Honey-Braised Baby Carrots with rosemary', desc: '' },
   { name: 'Sautéed Catalan Spinach', desc: '' },
   { name: 'Roman-Style Artichokes', desc: 'with green garlic and mint' },
   { name: 'Maque Choux', desc: 'with sautéed bacon, corn, red pepper, jalapeño, and sweet onion' },
   { name: 'Wilted Bitter Greens', desc: 'with blueberries and thyme' },
   { name: 'Cornbread Panzanella', desc: 'with grilled corn, cherry tomatoes, arugula, and balsamic vinaigrette' },
   { name: 'Potatoes au Gratin', desc: 'with cheddar and gruyère' },
   { name: 'Sweet Potato Gratin', desc: '' },
   { name: 'Cauliflower Gratin', desc: 'with curry and gruyère' },
   { name: 'Herb-Roasted Fingerling Potatoes', desc: '' },
   { name: 'Ratatouille', desc: 'with zucchini, tomatoes, eggplant, bell peppers, and sweet onion' },
   { name: 'Charred Shishito Peppers', desc: '' },
   { name: 'Succotash', desc: 'with okra, tomatoes, bell peppers, and bacon lardons' },
   { name: 'Grilled Asparagus', desc: 'with grapefruit supremes, walnuts, and citrus vinaigrette' },
];

const Sides2 = [
   { name: 'Haricots Verts', desc: 'with fried shallots and honey dijon vinaigrette' },
   { name: 'Red Potato Salad', desc: 'with cornichons and dill vinaigrette' },
   { name: 'Red Cabbage Slaw', desc: 'with spicy vinaigrette' },
   { name: 'Mac & Cheese', desc: 'with Vermont cheddar and breadcrumbs' },
   { name: 'Boston Baked Beans', desc: '' },
   { name: 'Grilled Corn Elote', desc: 'with chipotle crema, cotija cheese, chili powder, and lime' },
   { name: 'Roasted Sweet Potato Wedges', desc: 'with scallions, goat cheese, and pickled chilis' },
   { name: 'Sweet Potato Fries', desc: 'with merguez spice mix and yogurt dressing' },
   { name: 'Black Beans', desc: 'with caramelized onions and Carolina rice' },
   { name: 'Cassoulet', desc: 'with white beans, homemade sausage, and rosemary' },
   { name: 'Tuscan White Bean and Kale Stew', desc: '' },
   { name: 'Mandarin Orange Sweet Potato Purée', desc: 'with crispy shallots' },
   { name: 'Roasted Baby Brussel Sprouts', desc: 'with spicy caramelized apples and bacon' },
   { name: 'Grilled Radicchio', desc: 'with shaved parmesan, balsamic, and pine nuts' },
   { name: 'Braised Fennel', desc: 'with lemon zest and ouzo reduction' },
];

const menu = () => {
   return (
      <Layout>
         <Seo title='Menu' />
         <header className='w-full'>
            <div className='relative'>
               <div className='absolute inset-x-0' />
               <div className='relative h-full'>
                  <div className='absolute inset-0'>
                     <StaticImage
                        src='../images/Menu-Just-A-Nibble-Hero.jpg'
                        quality='100'
                        loading='eager'
                        className='h-full w-full object-cover'
                        alt='Just a Nibble Server handing out food'
                        objectPosition='50% 100%'
                     />
                     <div aria-hidden='true' className='opacity-70 absolute inset-0 bg-gradient-to-b from-black to-transparent' />
                  </div>
                  <div className='relative mx-auto max-w-7xl px-4 pt-96 text-center sm:px-6 lg:px-8 pb-36'>
                     <h1 className='mt-12 text-5xl font-hanley-script relative sm:text-6xl lg:text-8xl'>
                        <span className='block header'>Menu</span>
                     </h1>
                     <motion.hr
                        initial={{ width: 0, opacity: 0 }}
                        whileInView={{ width: 200, opacity: 1 }}
                        transition={{ duration: 1 }}
                        viewport={{ once: true }}
                        className='border-brand border rounded w-1/2 mx-auto my-3'
                     />
                     <motion.h2
                        initial={{ y: 10, opacity: 0 }}
                        whileInView={{ y: 0, opacity: 1 }}
                        transition={{ duration: 1 }}
                        viewport={{ once: true }}
                        className='text-slate-100 text-base md:text-xl uppercase tagline'
                     >
                        Take a look at our extensive menu
                     </motion.h2>
                  </div>
               </div>
            </div>
         </header>
         <Nibbles />
         <DinnerMenu />
         <LogoLinks />
      </Layout>
   );
};

const Nibbles = () => {
   return (
      <section className='relative w-full py-14 overflow-hidden'>
         <div className='absolute inset-0'>
            <StaticImage src='../images/slate.jpg' alt='Slate background' quality='50' className='h-full w-full object-cover' />
            <div aria-hidden='true' className='opacity-60 md:opacity-80 absolute inset-0 bg-black' />
         </div>
         <div className='relative flex items-center justify-center py-2 w-full mx-auto'>
            <StaticImage
               src='../images/Logo-Just-A-Nibble-Utensils-White.png'
               className='opacity-50 '
               placeholder='tracedSVG'
               alt='Just a Nibble Utensils Icon'
               quality={100}
               height={125}
            />
            <motion.p
               initial={{ opacity: 0 }}
               whileInView={{ opacity: 1 }}
               transition={{ duration: 1, delay: 1 }}
               viewport={{ once: true }}
               className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-brand font-hanley-sans text-center tracking-widest uppercase font-boldest z-20'
            >
               Nibbles
            </motion.p>
         </div>
         <a href={Bites} download className='pt-14 mx-auto underline uppercase proxima-body text-slate-100 text-center z-30 relative'>
            <p>Download Menu</p>
         </a>
         <div className='relative px-8 max-w-5xl mx-auto z-30 flex flex-row items-start justify-around gap-x-2 md:gap-x-8 mt-12'>
            <div>
               <motion.div
                  initial={{ y: 10, opacity: 0 }}
                  whileInView={{ y: 0, opacity: 1 }}
                  transition={{ duration: 1 }}
                  viewport={{ once: true }}
                  className='flex flex-row items-center justify-center mb-8'
               >
                  <hr className='border-brand border rounded w-8' />
                  <h2 className='text-2xl md:text-4xl font-hanley-script text-slate-100 mx-2'>Seafood</h2>
                  <hr className='border-brand border rounded w-8' />
               </motion.div>
               <div className='max-w-lg px-4'>
                  {Seafoods.map((seafood, i) => (
                     <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.35, delay: i * 0.2 }}
                        viewport={{ once: true }}
                        key={seafood.name}
                        className='relative text-center text-slate-200 mb-4'
                     >
                        <dl>
                           <dt>
                              <p className='font-hanley-sans md:text-base text-sm'>{seafood.name}</p>
                           </dt>
                           <dd className='proxima-body text-xs'>{seafood.desc}</dd>
                        </dl>
                     </motion.div>
                  ))}
               </div>
            </div>
            <div>
               <motion.div
                  initial={{ y: 10, opacity: 0 }}
                  whileInView={{ y: 0, opacity: 1 }}
                  transition={{ duration: 1 }}
                  viewport={{ once: true }}
                  className='flex flex-row items-center justify-center mb-8'
               >
                  <hr className='border-brand border rounded w-8' />
                  <h2 className='text-2xl md:text-4xl font-hanley-script text-slate-100 mx-2'>Meat</h2>
                  <hr className='border-brand border rounded w-8' />
               </motion.div>
               <div className='max-w-lg px-4'>
                  {Meats.map((meat, i) => (
                     <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.35, delay: i * 0.2 }}
                        viewport={{ once: true }}
                        key={meat.name}
                        className='relative text-center text-slate-200 mb-4'
                     >
                        <dl>
                           <dt>
                              <p className='font-hanley-sans md:text-base text-sm'>{meat.name}</p>
                           </dt>
                           <dd className='proxima-body text-xs'>{meat.desc}</dd>
                        </dl>
                     </motion.div>
                  ))}
               </div>
            </div>
         </div>
         <div className='relative px-8 max-w-5xl mx-auto z-30 flex flex-row items-start justify-around gap-x-2 md:gap-x-8 mt-12'>
            <div>
               <motion.div
                  initial={{ y: 10, opacity: 0 }}
                  whileInView={{ y: 0, opacity: 1 }}
                  transition={{ duration: 1 }}
                  viewport={{ once: true }}
                  className='flex flex-row items-center justify-center mb-8'
               >
                  <hr className='border-brand border rounded w-8' />
                  <h2 className='text-2xl md:text-4xl font-hanley-script text-slate-100 mx-2'>Tarts</h2>
                  <hr className='border-brand border rounded w-8' />
               </motion.div>
               <div className='max-w-lg px-4'>
                  {Tarts.map((tart, i) => (
                     <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: i * 0.2 }}
                        viewport={{ once: true }}
                        key={tart.name}
                        className='relative text-center text-slate-200 mb-4'
                     >
                        <dl>
                           <dt>
                              <p className='proxima-body md:font-hanley-sans text-xs'>{tart.name}</p>
                           </dt>
                        </dl>
                     </motion.div>
                  ))}
               </div>
               <motion.div
                  initial={{ y: 10, opacity: 0 }}
                  whileInView={{ y: 0, opacity: 1 }}
                  transition={{ duration: 1 }}
                  viewport={{ once: true }}
                  className='flex flex-row items-center justify-center mb-8 mt-8'
               >
                  <hr className='border-brand border rounded w-8' />
                  <h2 className='text-2xl md:text-4xl font-hanley-script text-slate-100 mx-2'>Flatbread</h2>
                  <hr className='border-brand border rounded w-8' />
               </motion.div>
               <div className='max-w-lg px-4'>
                  {Flatbreads.map((flatbread, i) => (
                     <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: i * 0.2 }}
                        viewport={{ once: true }}
                        key={flatbread.name}
                        className='relative text-center text-slate-200 mb-4'
                     >
                        <dl>
                           <dt>
                              <p className='proxima-body md:font-hanley-sans text-xs'>{flatbread.name}</p>
                           </dt>
                        </dl>
                     </motion.div>
                  ))}
               </div>
            </div>
            <div>
               <motion.div
                  initial={{ y: 10, opacity: 0 }}
                  whileInView={{ y: 0, opacity: 1 }}
                  transition={{ duration: 1 }}
                  viewport={{ once: true }}
                  className='flex flex-row items-center justify-center mb-8'
               >
                  <hr className='border-brand border rounded w-8' />
                  <h2 className='text-2xl md:text-4xl font-hanley-script text-slate-100 mx-2'>Crostini</h2>
                  <hr className='border-brand border rounded w-8' />
               </motion.div>
               <div className='max-w-lg px-0 md:px-4'>
                  {Crostinis.map((crostini, i) => (
                     <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: i * 0.2 }}
                        viewport={{ once: true }}
                        key={crostini.name}
                        className='relative text-center text-slate-200 mb-4'
                     >
                        <dl>
                           <dt>
                              <p className='proxima-body md:font-hanley-sans text-xs'>{crostini.name}</p>
                           </dt>
                        </dl>
                     </motion.div>
                  ))}
               </div>
            </div>
         </div>
      </section>
   );
};

const DinnerMenu = () => {
   return (
      <section className='relative pt-20'>
         <div className='relative flex items-center justify-center py-2 w-full mx-auto'>
            <StaticImage
               src='../images/Logo-Just-A-Nibble-Utensils-Brand.png'
               alt='Just a Nibble Utensils Icon'
               className=''
               placeholder='tracedSVG'
               quality={100}
               height={125}
            />
            <p className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-brand2 font-hanley-sans text-center tracking-widest uppercase font-boldest z-20'>
               Dinner Menu
            </p>
         </div>
         <div className='relative py-14'>
            <motion.h3
               initial={{ y: 10, opacity: 0 }}
               whileInView={{ y: 0, opacity: 1 }}
               transition={{ duration: 1 }}
               viewport={{ once: true }}
               className='font-hanley-sans text-brand2 text-center text-3xl md:text-5xl'
            >
               Salad & Entree Examples
            </motion.h3>
         </div>
         <a href={DinnerMenupdf} download className='underline uppercase proxima-body text-brand2 text-center z-30 relative'>
            <p>Download Menu</p>
         </a>
         <div className='relative px-8 max-w-5xl mx-auto z-30 flex flex-row items-starts justify-around gap-x-8 mt-14'>
            <div className='w-1/2'>
               <motion.div
                  initial={{ y: 10, opacity: 0 }}
                  whileInView={{ y: 0, opacity: 1 }}
                  transition={{ duration: 1 }}
                  viewport={{ once: true }}
                  className='flex flex-row items-center justify-center mb-8'
               >
                  <hr className='border-brand border rounded w-8' />
                  <h2 className='text-2xl md:text-4xl font-hanley-script text-brand2 mx-2'>Salads</h2>
                  <hr className='border-brand border rounded w-8' />
               </motion.div>
               <div className='max-w-lg px-4'>
                  {Salads.map((salad, i) => (
                     <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: i * 0.2 }}
                        viewport={{ once: true }}
                        key={salad.name}
                        className='relative text-center text-slate-800 mb-4'
                     >
                        <dl>
                           <dt>
                              <p className='font-hanley-sans md:text-base text-sm'>{salad.name}</p>
                           </dt>
                           <dd className='proxima-body text-xs'>{salad.desc}</dd>
                        </dl>
                     </motion.div>
                  ))}
               </div>
            </div>
            <div className='w-1/2'>
               <motion.div
                  initial={{ y: 10, opacity: 0 }}
                  whileInView={{ y: 0, opacity: 1 }}
                  transition={{ duration: 1 }}
                  viewport={{ once: true }}
                  className='flex flex-row items-center justify-center mb-8'
               >
                  <hr className='border-brand border rounded w-8' />
                  <h2 className='text-2xl md:text-4xl font-hanley-script text-brand2 mx-2'>Beef</h2>
                  <hr className='border-brand border rounded w-8' />
               </motion.div>
               <div className='max-w-lg px-4'>
                  {Beefs.map((beef, i) => (
                     <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: i * 0.2 }}
                        viewport={{ once: true }}
                        key={beef.name}
                        className='relative text-center text-slate-800 mb-4'
                     >
                        <dl>
                           <dt>
                              <p className='font-hanley-sans md:text-base text-sm'>{beef.name}</p>
                           </dt>
                           <dd className='proxima-body text-xs'>{beef.desc}</dd>
                        </dl>
                     </motion.div>
                  ))}
               </div>
               <motion.div
                  initial={{ y: 10, opacity: 0 }}
                  whileInView={{ y: 0, opacity: 1 }}
                  transition={{ duration: 1 }}
                  viewport={{ once: true }}
                  className='flex flex-row items-center justify-center mb-8 mt-8'
               >
                  <hr className='border-brand border rounded w-8' />
                  <h2 className='text-2xl md:text-4xl font-hanley-script text-brand2 mx-2'>Chicken</h2>
                  <hr className='border-brand border rounded w-8' />
               </motion.div>
               <div className='max-w-lg px-4'>
                  {Chickens.map((chicken, i) => (
                     <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: i * 0.2 }}
                        viewport={{ once: true }}
                        key={chicken.name}
                        className='relative text-center text-slate-800 mb-4'
                     >
                        <dl>
                           <dt>
                              <p className='font-hanley-sans md:text-base text-sm'>{chicken.name}</p>
                           </dt>
                           <dd className='proxima-body text-xs'>{chicken.desc}</dd>
                        </dl>
                     </motion.div>
                  ))}
               </div>
               <motion.div
                  initial={{ y: 10, opacity: 0 }}
                  whileInView={{ y: 0, opacity: 1 }}
                  transition={{ duration: 1 }}
                  viewport={{ once: true }}
                  className='flex flex-row items-center justify-center mb-8 mt-8'
               >
                  <hr className='border-brand border rounded w-8' />
                  <h2 className='text-2xl md:text-4xl font-hanley-script text-brand2 mx-2'>Pork</h2>
                  <hr className='border-brand border rounded w-8' />
               </motion.div>
               <div className='max-w-lg px-4'>
                  {Porks.map((pork, i) => (
                     <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: i * 0.2 }}
                        viewport={{ once: true }}
                        key={pork.name}
                        className='relative text-center text-slate-800 mb-4'
                     >
                        <dl>
                           <dt>
                              <p className='font-hanley-sans md:text-base text-sm'>{pork.name}</p>
                           </dt>
                           <dd className='proxima-body text-xs'>{pork.desc}</dd>
                        </dl>
                     </motion.div>
                  ))}
               </div>
            </div>
         </div>
         <div className='relative px-8 max-w-5xl mx-auto z-30 flex flex-row items-starts justify-around gap-x-8 mt-14'>
            <div className='w-1/2'>
               <motion.div
                  initial={{ y: 10, opacity: 0 }}
                  whileInView={{ y: 0, opacity: 1 }}
                  transition={{ duration: 1 }}
                  viewport={{ once: true }}
                  className='flex flex-row items-center justify-center mb-8'
               >
                  <hr className='border-brand border rounded w-8' />
                  <h2 className='text-2xl md:text-4xl font-hanley-script text-brand2 mx-2'>Seafood</h2>
                  <hr className='border-brand border rounded w-8' />
               </motion.div>
               <div className='max-w-lg px-4'>
                  {SeafoodsDinner.map((seafood, i) => (
                     <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: i * 0.2 }}
                        viewport={{ once: true }}
                        key={seafood.name}
                        className='relative text-center text-slate-800 mb-4'
                     >
                        <dl>
                           <dt>
                              <p className='font-hanley-sans md:text-base text-sm'>{seafood.name}</p>
                           </dt>
                           <dd className='proxima-body text-xs'>{seafood.desc}</dd>
                        </dl>
                     </motion.div>
                  ))}
               </div>
            </div>
            <div className='w-1/2'>
               <motion.div
                  initial={{ y: 10, opacity: 0 }}
                  whileInView={{ y: 0, opacity: 1 }}
                  transition={{ duration: 1 }}
                  viewport={{ once: true }}
                  className='flex flex-row items-center justify-center mb-8'
               >
                  <hr className='border-brand border rounded w-8' />
                  <h2 className='text-2xl md:text-4xl font-hanley-script text-brand2 mx-2'>Vegetarian</h2>
                  <hr className='border-brand border rounded w-8' />
               </motion.div>
               <div className='max-w-lg px-4'>
                  {Vegetarian.map((veggies, i) => (
                     <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: i * 0.2 }}
                        viewport={{ once: true }}
                        key={veggies.name}
                        className='relative text-center text-slate-800 mb-4'
                     >
                        <dl>
                           <dt>
                              <p className='font-hanley-sans md:text-base text-sm'>{veggies.name}</p>
                           </dt>
                           <dd className='proxima-body text-xs'>{veggies.desc}</dd>
                        </dl>
                     </motion.div>
                  ))}
               </div>
            </div>
         </div>
         <div className='w-full mt-8 px-8 mb-8'>
            <motion.div
               initial={{ y: 10, opacity: 0 }}
               whileInView={{ y: 0, opacity: 1 }}
               transition={{ duration: 1 }}
               viewport={{ once: true }}
               className='flex flex-row items-center justify-center mb-8'
            >
               <hr className='border-brand border rounded w-8' />
               <h2 className='text-2xl md:text-4xl font-hanley-script text-brand2 mx-2'>Sides</h2>
               <hr className='border-brand border rounded w-8' />
            </motion.div>
            <div className='flex flex-row max-w-5xl mx-auto z-30 items-starts justify-around gap-x-8 mt-14'>
               <div className='w-1/2'>
                  <dispatchEvent className='max-w-lg px-4'>
                     {Sides1.map((side, i) => (
                        <motion.div
                           initial={{ opacity: 0, y: 20 }}
                           whileInView={{ opacity: 1, y: 0 }}
                           transition={{ duration: 0.5, delay: i * 0.2 }}
                           viewport={{ once: true }}
                           key={side.name}
                           className='relative text-center text-slate-800 mb-4'
                        >
                           <dl>
                              <dt>
                                 <p className='font-hanley-sans md:text-base text-sm'>{side.name}</p>
                              </dt>
                              <dd className='proxima-body text-xs'>{side.desc}</dd>
                           </dl>
                        </motion.div>
                     ))}
                  </dispatchEvent>
               </div>
               <div className='w-1/2'>
                  {Sides2.map((side, i) => (
                     <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: i * 0.2 }}
                        viewport={{ once: true }}
                        key={side.name}
                        className='relative text-center text-slate-800 mb-4'
                     >
                        <dl>
                           <dt>
                              <p className='font-hanley-sans md:text-base text-sm'>{side.name}</p>
                           </dt>
                           <dd className='proxima-body text-xs'>{side.desc}</dd>
                        </dl>
                     </motion.div>
                  ))}
               </div>
            </div>
         </div>
         <div className='max-w-4xl mx-auto'>
            <StaticImage
               src='../images/Menu-Just-A-Nibble-Bottom-Banner.jpg'
               quality='100'
               loading='lazy'
               className='object-cover'
               alt='Just a Nibble Charcuterie board'
            />
         </div>
      </section>
   );
};
export default menu;
